<template>
  <v-sheet v-if="loaded">
    <v-breadcrumbs>
      <v-breadcrumbs-item :exact="true" :to="{ name: 'home', params: {} }"
        >Home</v-breadcrumbs-item
      >
      <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
      <v-breadcrumbs-item
        :exact="true"
        :to="{ name: 'dynamicQrCode', params: {} }"
      >
        Dynamic Qr Code
      </v-breadcrumbs-item>
      <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
      <v-breadcrumbs-item :to="{ name: 'worksheetQRCode', params: {} }">
        Worksheet
      </v-breadcrumbs-item>
    </v-breadcrumbs>

    <div class="d-print-none">
      <div class="text-h6 text-center">Entry Point: {{ domainUrl }}</div>
      <v-form ref="form">
        <div class="d-flex">
          <v-select
            class="mx-2"
            v-model="worksheetTypeId"
            :items="worksheetTypeDefinitions"
            label="Worksheet Type"
            item-text="worksheetType"
            item-value="id"
            :multiple="false"
            :rules="rules.select"
            clearable
          ></v-select>
          <v-btn
            class="my-auto"
            v-if="worksheetTypeId"
            :to="{
              name: 'worksheetTypeDefinitionView',
              params: { id: worksheetTypeId },
            }"
            icon
            ><v-icon>mdi-eye-arrow-right-outline</v-icon></v-btn
          >
          <v-btn class="my-auto" @click="editWorksheetType()" icon
            ><v-icon>mdi-tune</v-icon></v-btn
          >
        </div>
        <v-select
          class="mx-2"
          v-if="worksheetTypeFields && worksheetTypeFields.hasProcessType"
          v-model="processTypeId"
          :items="processTypes"
          label="Process Type"
          :multiple="false"
          item-text="name"
          item-value="id"
          :chips="false"
          deletable-chips
          :rules="rules.select"
          clearable
        ></v-select>
        <v-radio-group
          class="mx-2"
          row
          v-model="processDirection"
          v-if="processTypeId"
          label="For Process"
        >
          <v-radio value="Input" label="Input" />
          <v-radio value="Output" label="Output" />
        </v-radio-group>
        <v-radio-group
          class="mx-2"
          row
          v-model="processInputMethod"
          v-if="processTypeId && processDirection == 'Input'"
          label="Input Method"
        >
          <v-radio value="FIFO" label="FIFO" />
          <v-radio value="LIFO" label="LIFO" />
          <v-radio value="MANUAL" label="Manual" />
        </v-radio-group>
        <v-text-field
          class="mx-2"
          v-if="processTypeId && processDirection == 'Output'"
          v-model.number="costCoefficient"
          label="Output cost coefficient"
          :required="false"
          type="number"
          hint="Coefficient multiplier for output cost; a number between 0 and 1"
        />
        <v-switch
          class="mx-2"
          dense
          v-if="processDirection != 'Output'"
          v-model="inventorySelectionOnly"
          label="Select from inventory only"
        ></v-switch>

        <div class="mx-2 d-flex">
          <v-select
            :disabled="userChooseLocation"
            v-if="worksheetTypeFields && worksheetTypeFields.hasLocation"
            v-model="locationId"
            :items="locations"
            label="Default Location"
            :multiple="false"
            item-text="name"
            item-value="id"
            :chips="false"
            deletable-chips
            clearable
          />
          <v-checkbox
            v-model="userChooseLocation"
            :value="true"
            class="ml-4"
            label="Let user choose location"
          />
        </div>

        <v-text-field
          class="mx-2"
          v-if="worksheetTypeFields && worksheetTypeFields.hasDocumentNumber"
          v-model="documentNumberPrefix"
          label="Document Number Prefix"
          :required="false"
        />

        <v-text-field
          class="mx-2"
          v-if="worksheetTypeFields && worksheetTypeFields.hasDeductibleWeight"
          v-model.number="deductibleWeight"
          label="Deductible Weight"
          :required="false"
          type="number"
        >
          <template v-slot:append> Kg </template>
        </v-text-field>
        <v-text-field
          class="mx-2"
          v-if="worksheetTypeFields && worksheetTypeFields.hasTargetWeight"
          v-model.number="targetWeight"
          label="Target Weight"
          :required="false"
          type="number"
        >
          <template v-slot:append> Kg </template>
        </v-text-field>
        <div>
          <product-select
            required
            v-if="worksheetTypeFields && worksheetTypeFields.hasProduct"
            class="mx-2 mb-6"
            v-model="products"
            return-object
            :multiple="multipleProducts"
          />
        </div>
        <v-textarea
          hide-details
          class="mx-2"
          label="Memo"
          v-model="memo"
          outlined
          v-if="worksheetTypeFields && worksheetTypeFields.hasMemo"
        />
        <v-switch
          class="mx-2"
          v-if="worksheetTypeFields && worksheetTypeFields.hasEntryPhoto"
          hide-details
          dense
          v-model="requirePhoto"
          :label="`Require photo: ${requirePhoto ? 'Yes' : 'No'}`"
        ></v-switch>
        <v-switch
          class="mx-2"
          v-if="worksheetTypeFields && worksheetTypeFields.hasSignature"
          hide-details
          dense
          v-model="requireSignature"
          :label="`Require Signature: ${requireSignature ? 'Yes' : 'No'}`"
        ></v-switch>

        <div v-if="requireSignature">
          <div
            class="d-flex justify-space-between"
            v-for="(signature, index) in signatures"
            :key="index"
          >
            <span class="my-auto">{{ index + 1 }}.</span>
            <v-text-field
              class="ma-2"
              outlined
              hide-details
              dense
              v-model="signature.signatureHeader"
              label="Signature Header"
            />
            <v-text-field
              class="ma-2"
              outlined
              hide-details
              dense
              v-model="signature.signatureFooter"
              label="Signature Footer"
            />
            <v-btn class="my-auto" @click="deleteSignature(index)" icon
              ><v-icon>mdi-delete</v-icon></v-btn
            >
          </div>
          <div class="my-5 text-center">
            <v-btn outlined @click="addSignature">
              Add Signature
              <v-icon class="ml-2">mdi-draw-pen</v-icon>
            </v-btn>
          </div>
        </div>
        <v-switch
          class="mx-2"
          hide-details
          dense
          v-model="requireAccessCode"
          label="Require access code"
        ></v-switch>
        <v-text-field
          class="mx-2 my-2"
          v-if="requireAccessCode"
          outlined
          hide-details
          v-model="accessCode"
          label="Access Code"
        />
      </v-form>
      <div class="text-center my-6">
        <v-btn @click="saveDynamicQrCode()"
          >{{ qrCodeId ? "Update" : "Create" }} QR Code</v-btn
        >
      </div>
    </div>
  </v-sheet>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
const ProductSelect = () => import("@/components/product/ProductSelect.vue");
import { parseDomain } from "parse-domain";
import { v4 as uuidv4 } from "uuid";
import { getDynamicQrCode } from "@/services/dynamicQrCode";

export default {
  data: () => {
    return {
      loaded: false,
      worksheetTypeId: null,
      name: null,
      qrCodeId: null,
      url: null,
      processTypeId: null,
      processDirection: null,
      processInputMethod: null,
      inventorySelectionOnly: false,
      locationId: null,
      userChooseLocation: false,
      products: null,
      deductibleWeight: 0,
      targetWeight: 0,
      costCoefficient: 1,
      memo: null,
      documentNumberPrefix: null,
      domainUrl: null,
      requirePhoto: true,
      requireSignature: false,
      signatures: [],
      requireAccessCode: false,
      accessCode: null,
      uuid: null,
      rules: {
        select: [(v) => !!v || "Item is required"],
      },
    };
  },
  computed: {
    ...mapGetters("processes", ["processTypes"]),
    ...mapGetters("locations", ["locations"]),
    ...mapGetters("worksheetTypeDefinition", ["worksheetTypeDefinitions"]),
    qrCodeUrl() {
      return `${this.domainUrl}/qr-dispatch/${this.uuid}/`;
    },
    worksheetTypeFields() {
      if (
        this.worksheetTypeDefinitions &&
        this.worksheetTypeDefinitions.length > 0 &&
        this.worksheetTypeId
      ) {
        return this.worksheetTypeDefinitions.find(
          (t) => t.id == this.worksheetTypeId
        );
      } else {
        return {};
      }
    },
    multipleProducts() {
      return this.processDirection == "Input" ? true : false;
    },
  },
  components: {
    ProductSelect,
  },
  watch: {
    requireAccessCode(val) {
      if (!val) {
        this.accessCode = null;
      }
    },
    processInputMethod(newVal) {
      if (newVal == "MANUAL") {
        this.inventorySelectionOnly = true;
      } else {
        this.inventorySelectionOnly = false;
      }
    },
  },
  async created() {
    this.$emit("showParent", false);

    await this.fetchWorksheetTypeDefinitions();
    this.loadProcessTypes();
    this.loadLocations();

    this.uuid = this.$route.params.uuid;
    if (this.uuid) {
      await this.initWorksheet(this.uuid);
    }

    const parseResult = parseDomain(window.location.hostname);
    const { domain, topLevelDomains } = parseResult;

    if (domain) {
      const topLevel = topLevelDomains.join(".");
      this.domainUrl = `https://factory.${domain}.${topLevel}/#`;
    } else {
      this.domainUrl = `http://localhost:${process.env.VUE_APP_PORT}/#`;
    }

    this.loaded = true;
  },

  methods: {
    ...mapActions("processes", ["loadProcessTypes"]),
    ...mapActions("locations", ["loadLocations"]),
    ...mapActions("messages", [
      "addMessage",
      "addSuccessMessage",
      "addErrorMessage",
    ]),
    ...mapActions("navigation", ["hideDrawer"]),
    ...mapActions("dynamicQrCode", [
      "createDynamicQrCode",
      "updateDynamicQrCode",
    ]),
    ...mapActions("worksheetTypeDefinition", ["fetchWorksheetTypeDefinitions"]),
    ...mapActions("categoryStore", ["fetchCategory"]),

    async print() {
      await this.hideDrawer();
      window.print();
    },
    selectedProcessType() {
      if (this.processTypeId && this.processTypeId > 0) {
        return this.processTypes.find((p) => p.id == this.processTypeId);
      } else {
        return null;
      }
    },
    selectedLocation() {
      if (this.locationId && this.locationId > 0) {
        return this.locations.find((l) => l.id == this.locationId);
      } else {
        return null;
      }
    },
    async initWorksheet(uuid) {
      const qrCode = await this.loadQrCode(uuid);
      if (qrCode) {
        this.requireAccessCode = qrCode.requireAccessCode;
        this.qrCodeId = qrCode.id;
        const steps = JSON.parse(qrCode.jsonData);
        const postStep = steps[0];
        this.worksheetTypeId = postStep.type;

        if (postStep.params && this.worksheetTypeFields) {
          if (this.worksheetTypeFields.hasProcessType) {
            const processTypeId = postStep.params.processTypeId;
            this.processDirection = postStep.params.processDirection;
            if (
              this.processDirection == "Input" &&
              postStep.params.processInputMethod
            ) {
              this.processInputMethod = postStep.params.processInputMethod;
            }
            if (processTypeId) {
              const processType = await this.loadCategory(processTypeId);

              if (processType) {
                this.processTypeId = processType.id;
              }
            }
          }
          if (this.worksheetTypeFields.hasLocation) {
            const locationId = postStep.params.locationId;
            if (locationId) {
              await this.loadCategory(locationId).then((location) => {
                this.locationId = location.id;
              });
            }
            this.userChooseLocation = postStep.params.userChooseLocation;
          }
          if (this.worksheetTypeFields.hasProduct) {
            const productIds = postStep.params.productIds;
            let products = null;
            if (productIds && productIds.length > 0) {
              products = [];
              for (const productId of productIds) {
                await this.loadCategory(productId).then((product) => {
                  products.push(product);
                });
              }
            } else {
              const productId = postStep.params.productId;
              if (productId) {
                await this.loadCategory(productId).then((product) => {
                  products = product;
                });
              }
            }
            this.products = products;
          }

          if (this.worksheetTypeFields.hasSignature) {
            this.requireSignature = postStep.params.requireSignature;
            this.signatures = postStep.params.signatures;
          }

          this.memo = postStep.params.memo;
          this.targetWeight = postStep.params.targetWeight;
          this.deductibleWeight = postStep.params.deductibleWeight;
          this.requirePhoto = postStep.params.requirePhoto;
          this.inventorySelectionOnly = postStep.params.inventorySelectionOnly;
          this.costCoefficient = postStep.params.costCoefficient;
          this.documentNumberPrefix = postStep.params.documentNumber;
        }
      }
    },
    async loadQrCode(uuid) {
      return await getDynamicQrCode(uuid)
        .then((response) => {
          return response.data;
        })
        .catch(() => {
          this.addErrorMessage("Error loading dynamicQrCode");
          return null;
        });
    },
    async loadCategory(id) {
      if (id) {
        return await this.fetchCategory(id)
          .then((category) => {
            return category;
          })
          .catch((error) => {
            this.addErrorMessage("Item not found; " + error.data.message);
            return null;
          });
      } else {
        return null;
      }
    },
    saveDynamicQrCode() {
      if (this.uuid == null) {
        this.uuid = uuidv4();
      }
      if (!this.$refs.form.validate()) {
        this.addMessage("Please check your input");
        return;
      } else if (
        this.worksheetTypeFields.hasProduct &&
        (this.products == null || this.products.length < 1)
      ) {
        this.addMessage("Please choose a product");
        return;
      }

      const params = this.prepareWorksheetParams();
      const step1 = {
        requestMethod: "POST",
        endpoint: "/processWorksheet",
        type: this.worksheetTypeId,
        params,
      };

      // const route = this.$router.resolve({ name: 'MyWorksheets' });
      // const myWorksheetURL = new URL(route.href, window.location.href).href;

      // Redirect supports 2 methods;  route or url
      const step2 = {
        requestMethod: "REDIRECT",
        route: { name: "MyWorksheets" },
      };

      const searchText = this.generateSearchText();
      const qrCode = {
        type: "EXECUTION",
        businessType: "WORKSHEET",
        allowPublic: false,
        uuid: this.uuid,
        jsonData: JSON.stringify([step1, step2]),
        accessCode: this.accessCode,
        searchText,
      };

      if (this.qrCodeId) {
        qrCode["id"] = this.qrCodeId;
        this.updateDynamicQrCode(qrCode)
          .then(() => {
            this.addSuccessMessage("QR code updated successfully");
            this.viewQRCode(this.uuid);
          })
          .catch((error) => {
            this.addErrorMessage(
              "Error updating QR code; " + error.data.message
            );
          });
      }
      //Create new QR Code
      else {
        this.createDynamicQrCode(qrCode)
          .then(() => {
            this.addSuccessMessage("QR code saved successfully");
            this.viewQRCode(this.uuid);
          })
          .catch((error) => {
            this.addErrorMessage("Error saving QR code; " + error.data.message);
          });
      }
    },
    prepareWorksheetParams() {
      const params = {};

      params.worksheetTypeId = this.worksheetTypeId;

      const processType = this.selectedProcessType();
      if (this.worksheetTypeFields.hasProcessType && processType) {
        params.processTypeId = processType.id;
        params.processDirection = this.processDirection;
        if (this.processDirection == "Input") {
          params.processInputMethod = this.processInputMethod
            ? this.processInputMethod
            : "FIFO";
        }
        if (this.processDirection == "Output") {
          params.costCoefficient = this.costCoefficient;
        }
      }

      params.inventorySelectionOnly = this.inventorySelectionOnly;

      if (this.worksheetTypeFields.hasLocation) {
        params.userChooseLocation = this.userChooseLocation;
        const location = this.selectedLocation();
        if (location) {
          params.locationId = location.id;
        }
      }

      if (this.worksheetTypeFields.hasProduct && this.products) {
        if (
          this.products &&
          (this.products.length == 1 || !this.multipleProducts)
        ) {
          if (this.multipleProducts) {
            params.productId = this.products[0].id;
          } else {
            params.productId = this.products.id;
          }
          params.productIds = null;
        } else if (this.products && this.products.length > 1) {
          params.productIds = this.products.map((p) => p.id);
          params.productId = null;
        } else {
          params.productId = null;
          params.productIds = null;
        }
      }

      if (this.worksheetTypeFields.hasMemo) {
        params.memo = this.memo;
      }

      if (this.worksheetTypeFields.hasDeductibleWeight) {
        params.deductibleWeight = this.deductibleWeight;
      }

      if (this.worksheetTypeFields.hasTargetWeight) {
        params.targetWeight = this.targetWeight;
      }

      if (this.worksheetTypeFields.hasDocumentNumber) {
        params.documentNumber = this.documentNumberPrefix;
      }

      if (this.worksheetTypeFields.hasEntryPhoto) {
        params.requirePhoto = this.requirePhoto;
      }

      if (this.worksheetTypeFields.hasSignature) {
        params.requireSignature = this.requireSignature;
        params.signatures = this.signatures;
      }

      return params;
    },
    generateSearchText() {
      const text = [];
      text.push(
        `Type: ${
          this.worksheetTypeDefinitions.find(
            (t) => t.id == this.worksheetTypeId
          ).worksheetType
        }`
      );
      const processType = this.selectedProcessType();
      const location = this.selectedLocation();
      if (processType) {
        text.push(
          `Process Type: ${processType.name} (${this.processDirection}${
            this.processDirection == "Input" && this.processInputMethod
              ? ` ${this.processInputMethod}`
              : ""
          })`
        );
      }
      if (this.processDirection == "Output" && this.costCoefficient) {
        text.push(`Output cost coefficient: ${this.costCoefficient}`);
      }
      if (this.inventorySelectionOnly) {
        text.push("From inventory only");
      }
      if (location) {
        text.push(`Location: ${location.name}`);
      }
      if(this.userChooseLocation){
        text.push('Location: User Choose Location');
      }
      if (this.products) {
        if (!this.multipleProducts || this.products.length == 1) {
          if (this.multipleProducts) {
            text.push(`Product: ${this.products[0].name}`);
          } else {
            text.push(`Product: ${this.products.name}`);
          }
        } else if (this.multipleProducts && this.products.length > 0) {
          text.push(`Products: ${this.products.map((p) => p.name).join(", ")}`);
        }
      }
      if (this.targetWeight) {
        text.push(`Target weight: ${this.targetWeight}`);
      }
      if (this.deductibleWeight) {
        text.push(`Deductible weight: ${this.deductibleWeight}`);
      }
      if (this.documentNumberPrefix) {
        text.push(`Document # prefix: ${this.documentNumberPrefix}`);
      }
      if (this.memo) {
        text.push(`Memo: ${this.memo}`);
      }
      if (this.requirePhoto) {
        text.push(`Require photo: ${this.requirePhoto ? "Yes" : "No"}`);
      }
      if (this.requireSignature) {
        text.push(`Require signature: ${this.requireSignature ? "Yes" : "No"}`);
      }
      return text.join("\n");
    },
    viewQRCode(uuid) {
      this.$router.push({ path: "/qr-code/worksheet/" + uuid + "/" });
    },
    editWorksheetType() {
      this.$router.push({ name: "worksheetTypeDefinition" });
    },
    addSignature() {
      if (!this.signatures) {
        this.signatures = [];
      }
      const index = this.signatures.length + 1;
      const newSignature = {
        id: index,
        signatureHeader: "",
        signatureFooter: "",
        img: null,
      };
      this.signatures.push(newSignature);
    },
    deleteSignature(index) {
      this.signatures.splice(index, 1);
    },
  },
};
</script>

<style></style>
